import { useQuery } from '@tanstack/react-query';
import { APIClient, APIEndpoint } from '../lib/api_client';
import { Activity, ConnectionState, ResyVenue, ResyVenueTimeSlot } from 'src/types/interfaces';
import { formatTimeSlotDate } from 'src/lib/utils';


export interface ResyConnectionResponse {
  connectionState: ConnectionState;
}

const useResy = () => {
  const reservations = useQuery({
    queryKey: ['resyReservations'],
    queryFn: async () => {
      const response = await APIClient.get(`${APIEndpoint}/services/resy/reservations/`);
      return response.data;
    },
    retry: true,
    staleTime: 1 * 60 * 1000,
  });

  const status = useQuery({
    queryKey: ['resyConnection'],
    queryFn: async () => {
      try {
        const response = await APIClient.get<ResyConnectionResponse>(`${APIEndpoint}/services/resy/status/`);
        return response.data.connectionState;
      } catch (error) {
        return ConnectionState.Unknown;
      }
    },
    retry: true,
    staleTime: 10 * 60 * 1000,
  });

  const find = async (activity: Activity): Promise<ResyVenue> => {
    const response = await APIClient.get<ResyVenue>(`${APIEndpoint}/services/resy/find/`, {
      headers: { Accept: 'application/json' },
      params: { resyId: activity.resyId }
    });
    return response.data;
  }

  const book = async (venue: ResyVenue, slot: ResyVenueTimeSlot) => {
    const date = formatTimeSlotDate(slot.date.start)
    const response = await APIClient.post(`${APIEndpoint}/services/resy/book/`,
      {
        configId: slot.config.token,
        partySize: slot.size.max,
        day: `${date.year}-${date.month}-${date.day}`,
      }
    );
    return response.data;
  }

  const cancel = async (activity: Activity) => {
    const response = await APIClient.post(`${APIEndpoint}/services/resy/cancel/`,
      { resyToken: activity.resy?.reservation.resyToken}
    );
    return response.data;
  }

  return { reservations, status, find, book, cancel };
}

export default useResy;

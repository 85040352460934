import useResy from 'src/hooks/useResy';
import uberLogo from '../../assets/uber-logo.png';
import resyLogo from '../../assets/resy-logo.png';
import ResyBookingModal from '../Integrations/ResyBookingModal';
import React, { useEffect, useState } from 'react';
import PhotoGallery from '../ActivityDrawer/PhotoGallery';
import lyftLogo from '../../assets/lyft-logo.png';
import { useSnackbar } from 'src/hooks/SnackbarProvider';
import { useSortable } from '@dnd-kit/sortable';
import { usePlan } from 'src/hooks/PlanProvider';
import { Person, ResyReservation, ResyVenue, ResyVenueTimeSlot } from 'src/types/interfaces';
import { GroupAdd, ExpandMore, AccessTime, PhotoLibrary } from '@mui/icons-material';
import { formatedTime } from 'src/lib/utils';
import { CSS } from '@dnd-kit/utilities';
import { Card, CardContent, CardHeader, IconButton, Collapse, Chip, Badge, Modal, Typography } from '@mui/material';
import { ActivityRendererProps } from '../PlanStepper/PlanStepperBase';
import './PlanBuilderActivityRenderer.scss';


export const PlanBuilderActivityRenderer: React.FC<ActivityRendererProps> = ({ activity, id, onUpdateActivity = () => { } }) => {
  const { triggerSnackbar } = useSnackbar();
  const { selectedPlan, setSelectedPlanActivities, savePlan } = usePlan()
  const { setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const { reservations, find } = useResy();
  const [venue, setVenue] = useState<ResyVenue | null>(null)
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 20 : undefined,
  };

  const [people, setPeople] = useState<Person[]>([])
  const [photoLibraryOpen, setPhotoLibraryOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showResyBooking, setShowResyBooking] = useState(false);

  const handleExpandClick = () => setIsExpanded(!isExpanded);

  const handleResyBookingComplete = (reservation: ResyReservation, venue: ResyVenue, slot: ResyVenueTimeSlot) => {
    const updatedActivities = selectedPlan.activities.map(existingActivity =>
      existingActivity.placeId === activity.placeId
        ? { ...existingActivity, resy: { reservation, venue, slot } }
        : existingActivity
    );
    setSelectedPlanActivities(updatedActivities);
    savePlan({ ...selectedPlan, activities: updatedActivities })
    triggerSnackbar(`Reservation for ${formatedTime(slot.date.start)} successful!`)
  }

  const handleResyClick = async () => {
    const venue = await find(activity)
    setVenue(venue);
    setShowResyBooking(true);
  };

  const handleUberClick = async () => {
    console.debug("TODO: implement handleUberClick()")
  };

  const handleLyftClick = async () => {
    console.debug("TODO: implement handleLyftClick()")
  }

  const handleFriendsClick = async () => {
    setPeople([...people, {} as Person])
    console.debug("TODO: implement handleFriendsClick()")
  };

  useEffect(() => {
    if (reservations.data) {
      console.debug("TODO: implement Resy reservation state reconciliation:", reservations.data);
    }
  }, [reservations])

  return (
    <>
      <Modal open={photoLibraryOpen} onClose={() => setPhotoLibraryOpen(false)}>
        <PhotoGallery photos={activity.photos} classNames={['activity-card-photo']} />
      </Modal>
      {venue && (
        <ResyBookingModal
          open={showResyBooking}
          activity={activity}
          venue={venue}
          onClose={() => setShowResyBooking(false)}
          onBookingComplete={handleResyBookingComplete}
        />
      )}
      <div ref={setNodeRef} style={style} data-dnd-dragging={isDragging}>
        <Card className={`activity-card ${isExpanded ? 'expanded' : ''}`}>
          <CardHeader
            className="activity-card-header"
            title={
              <div className="activity-card-content">
                {activity.startTime && (
                  <div className="time-chip">
                    <Chip icon={<AccessTime />} label={activity.startTime} variant="outlined" color="primary" />
                  </div>
                )}
                <div className="activity-header-buttons">
                  {activity.resyId && (
                    <IconButton onClick={handleResyClick}>
                      {activity.resy?.reservation?.reservationId ? (
                        <Badge className="activity-badge-complete" badgeContent={"✓"} color="secondary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                          <img src={resyLogo} alt="Resy" />
                        </Badge>
                      ) : (
                        <Badge className="activity-badge" badgeContent={"+"} color="primary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                          <img src={resyLogo} alt="Resy" />
                        </Badge>
                      )}
                    </IconButton>
                  )}
                  <IconButton onClick={handleUberClick}>
                    <Badge className="activity-badge" badgeContent={"+"} color="primary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                      <img src={uberLogo} alt="Uber" />
                    </Badge>
                  </IconButton>
                  <IconButton onClick={handleLyftClick}>
                    <Badge className="activity-badge" badgeContent={"+"} color="primary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                      <img src={lyftLogo} alt="Lyft" />
                    </Badge>
                  </IconButton>
                </div>
              </div>
            }
            action={
              <>
                <IconButton size="large" className="friends-button" onClick={handleFriendsClick}>
                  <Badge className="activity-badge" badgeContent={people.length || 0} color="primary" overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}><GroupAdd /></Badge>
                </IconButton>
                <IconButton onClick={() => setPhotoLibraryOpen(true)} size="small" ><PhotoLibrary /></IconButton>
                {activity.resy?.reservation.reservationId && (
                  <IconButton onClick={handleExpandClick} className={`expand-more ${isExpanded ? 'expanded' : ''}`} size="small" ><ExpandMore /></IconButton>
                )}
              </>
            }
          />
          {activity.resy?.reservation.reservationId && (
            <CardContent className="activity-card-content">
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Typography>Reservation: {formatedTime(activity.resy.slot.date.start)}</Typography>
                <Typography>Party: {activity.resy.slot.size.max}</Typography>
              </Collapse>
            </CardContent>
          )}
        </Card>
      </div>
    </>
  );
};

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Grid, IconButton, InputAdornment } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../hooks/AuthProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { isValidEmail, isValidPassword } from '../../lib/utils';
import { useSnackbar } from 'src/hooks/SnackbarProvider';
import './Login.scss';
import BrandNameAnimation from '../CanvasAnimations/BrandNameAnimation';
import { usePlan } from 'src/hooks/PlanProvider';

/**
 * Renders a login component with options for email/password login and Google sign-in.
 * The component has two states: expanded (email/password form) and collapsed (Google sign-in).
 */
export default function Login() {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { setSelectedPlanDefault } = usePlan();
  const { triggerSnackbar } = useSnackbar();


  const responseMessage = async (response: CredentialResponse) => {
    try {
      await auth.handleSocialAuthTokenExchange(response)
      setSelectedPlanDefault();
      navigate("/")
    } catch (e) {
      triggerSnackbar("Social authentication failed; try again.", 3000, "error")
    }
  };

  const errorMessage = () => {
    console.log("An unknown error occurred.");
  };

  const handleLoginExpandClick = () => setExpanded(!expanded)

  const handleLoginSubmitClick = async () => {
    try {
      setSubmitDisabled(true);
      await auth.handleLoginSubmit(email, password)
      setSelectedPlanDefault();
      navigate("/")
    } catch {
      triggerSnackbar("Login failed; try again.", 3000, "error")
      setSubmitDisabled(false);
    }
  };

  useEffect(() => {
    const isValid = isValidEmail(email) && isValidPassword(password)

    setSubmitDisabled(!isValid)
  }, [email, password])

  const handleLoginFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, formPart: string) => {
    switch (formPart) {
      case 'email': {
        setEmail(event.target.value)
        break;
      }
      case 'password': {
        setPassword(event.target.value)
        break;
      }
    }
  }

  if (expanded) {
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <BrandNameAnimation />
          <Grid container padding={'12px;'} spacing={3}>
            <Grid item xs={12}>
              <TextField required fullWidth
                label="Email"
                inputProps={{ "data-testid": "login-email-input" }}
                value={email}
                onChange={(e) => handleLoginFormChange(e, 'email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth required
                label="Password"
                value={password}
                type={showPassword ? "text" : "password"}
                onChange={(e) => handleLoginFormChange(e, 'password')}
                inputProps={{ "data-testid": "login-password-input" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword((prev) => !prev)} onMouseDown={(event: React.MouseEvent) => event.preventDefault()} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs><Button data-testid="login-back-button" onClick={() => handleLoginExpandClick()}>Back</Button></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs><Button variant="contained" data-testid="login-submit-button" disabled={submitDisabled} onClick={() => handleLoginSubmitClick()}>Submit</Button></Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  } else {
    return (
      <Card className="login" sx={{ maxWidth: 345 }}>
        <CardContent>
          <BrandNameAnimation />
          <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={12}><GoogleLogin theme="outline" onSuccess={responseMessage} onError={errorMessage} auto_select={false} /></Grid>
            <Grid item xs={12}><Button variant="contained" onClick={() => navigate("/register")} data-testid="register-button" className="login-button">Sign Up</Button></Grid>
            <Grid item xs={12}><Button variant="outlined" onClick={() => handleLoginExpandClick()} data-testid="login-button" className="login-button">Email Login</Button></Grid>
          </Grid>
        </CardContent>
      </Card >
    );
  }
}
import React from 'react';
import { Card, CardProps } from '@mui/material';
import { getRandomGradientForTypes } from 'src/lib/utils';

interface GradientCardProps extends CardProps {
  types: string[];
}

export const GradientCard: React.FC<GradientCardProps> = ({ types, sx, ...rest }) => {
  const gradient = getRandomGradientForTypes(types);

  return (
    <Card
      sx={{
        background: gradient,
        boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
        color: '#333',
        ...sx,
      }}
      {...rest}
    />
  );
};

import React, { useRef, useEffect } from 'react';

const BrandNameAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    // Ensure fonts are loaded before running animation code.
    document.fonts.ready.then(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      // Use fixed internal dimensions.
      const canvasWidth = 300;
      const canvasHeight = 60;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const ctx = canvas.getContext('2d')!;
      ctx.textBaseline = 'middle';

      // --- Font and Text Settings ---
      const baseText = 'mindara';
      const baseFontSize = 36;
      const shadowFontSize = 36; // For the moving shadow "i" and extra "o"
      const baseFont = `${baseFontSize}px Audiowide`;
      const shadowFont = `${shadowFontSize}px Audiowide`;
      const oOffset = 8;

      // --- Colors ---
      const baseColor = '#ffafbd';   // white
      const neonPink = '#ea00ff';     // neon pink (used initially for "ai")
      const neonTeal = '#00FFFF';     // neon teal (for the "io" group)

      // --- Helper Functions for Colors ---
      const hexToRgb = (hex: string) => {
        hex = hex.replace(/^#/, '');
        if (hex.length === 3) {
          hex = hex.split('').map(c => c + c).join('');
        }
        const num = parseInt(hex, 16);
        return { r: (num >> 16) & 255, g: (num >> 8) & 255, b: num & 255 };
      };

      const rgbToHex = (r: number, g: number, b: number) =>
        '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');

      const baseRGB = hexToRgb(baseColor);
      const neonPinkRGB = hexToRgb(neonPink);
      const neonTealRGB = hexToRgb(neonTeal);

      // --- Linear Interpolation ---
      const lerp = (a: number, b: number, t: number) => a + (b - a) * t;
      const lerpColor = (
        colorA: { r: number; g: number; b: number },
        colorB: { r: number; g: number; b: number },
        t: number
      ) => {
        const r = Math.round(lerp(colorA.r, colorB.r, t));
        const g = Math.round(lerp(colorA.g, colorB.g, t));
        const b = Math.round(lerp(colorA.b, colorB.b, t));
        return rgbToHex(r, g, b);
      };

      // --- Easing Function for Fade & Smooth Centering ---
      const easeOutQuad = (t: number) => t * (2 - t);

      // --- Animation Phase Durations (in ms) ---
      const restDuration = 5000;       // Phase 0: Rest for 8 seconds.
      const moveDuration = 3500;       // Phase 1: "i" shadow moves (and fades in) & extra "o" appears; base "a" transitions white→neon pink.
      const transferDuration = 1500;   // Phase 2: "io" group transitions to neon teal; base "a" transitions neon pink→white.
      const holdDuration = 750;       // Phase 3: Hold phase.
      const fadeDuration = 750;       // Phase 4: "io" group fades out.
      const totalDuration = restDuration + moveDuration + transferDuration + holdDuration + fadeDuration;

      // --- Compute Base Text Positions in Relative Coordinates ---
      ctx.font = baseFont;
      const letters = baseText.split('');
      const letterWidths = letters.map(letter => ctx.measureText(letter).width);
      const basePositions: number[] = [];
      let relX = 0;
      for (let i = 0; i < letters.length; i++) {
        basePositions.push(relX);
        relX += letterWidths[i];
      }
      const baseWidth = relX;

      // --- Compute Extra ("io") Group Dimensions ---
      ctx.font = shadowFont;
      const shadowIWidth = ctx.measureText('i').width; // width for the moving shadow "i"
      const extraOWidth = ctx.measureText('o').width;    // width for the extra "o"

      // The "i" shadow initially is centered over the base text's "i" (index 1).
      const baseIWidth = letterWidths[1];
      const initialShadowX_rel = basePositions[1] + baseIWidth / 2 - shadowIWidth / 2;
      // NEW: Compute the intermediate target so that the shadow "i" lands to the right of the last letter "a"
      const lastIndex = letters.length - 1;
      const intermediateShadowX_rel = basePositions[lastIndex] + letterWidths[lastIndex];

      // --- Dynamic State Variables ---
      let shadowDrawColor = neonPink; // Color for the "i" shadow (will transition in Phase 2)
      let oDrawColor = baseColor;     // Color for the extra "o"
      let lastAColor = baseColor;     // Color for the base text's last letter "a"
      let shadowOpacity = 0;          // Start at 0; will fade in during Phase 1.
      let oOpacity = 0;
      let shadowX_rel = initialShadowX_rel;

      // For smooth, non-linear centering, maintain a current group offset.
      let currentGroupOffsetX = 0;

      // --- Animation Timing ---
      let animationFrameId: number;
      const startTime = performance.now();

      function draw() {
        const currentTime = performance.now();
        const elapsedRaw = currentTime - startTime;
        const elapsed = elapsedRaw % totalDuration;

        // Clear the canvas using the new background color.
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);

        // --- Animation Phases ---
        if (elapsed < restDuration) {
          // Phase 0: Rest phase (8 seconds).
          shadowX_rel = initialShadowX_rel;
          shadowOpacity = 0;
          oOpacity = 0;
          lastAColor = baseColor;
        } else if (elapsed < restDuration + moveDuration) {
          // Phase 1: "i" shadow moves from initial to intermediate, fading in.
          const t1 = (elapsed - restDuration) / moveDuration;
          shadowX_rel = lerp(initialShadowX_rel, intermediateShadowX_rel, t1);
          shadowOpacity = t1;
          oOpacity = t1;
          lastAColor = lerpColor(baseRGB, neonPinkRGB, t1);
          shadowDrawColor = neonPink;
          oDrawColor = baseColor;
        } else if (elapsed < restDuration + moveDuration + transferDuration) {
          // Phase 2: Transition to neon teal for the "io" group; base "a" transitions neon pink→white.
          const t2 = (elapsed - restDuration - moveDuration) / transferDuration;
          shadowX_rel = intermediateShadowX_rel;
          shadowOpacity = 1;
          oOpacity = 1;
          shadowDrawColor = lerpColor(neonPinkRGB, neonTealRGB, t2);
          oDrawColor = lerpColor(baseRGB, neonTealRGB, t2);
          lastAColor = lerpColor(neonPinkRGB, baseRGB, t2);
        } else if (elapsed < restDuration + moveDuration + transferDuration + holdDuration) {
          // Phase 3: Hold phase.
          shadowX_rel = intermediateShadowX_rel;
          shadowOpacity = 1;
          oOpacity = 1;
          shadowDrawColor = neonTeal;
          oDrawColor = neonTeal;
          lastAColor = baseColor;
        } else {
          // Phase 4: Fade phase: "io" group fades out.
          const t4 = (elapsed - restDuration - moveDuration - transferDuration - holdDuration) / fadeDuration;
          const fadeFactor = easeOutQuad(t4);
          shadowX_rel = intermediateShadowX_rel;
          shadowOpacity = 1 - fadeFactor;
          oOpacity = 1 - fadeFactor;
          shadowDrawColor = neonTeal;
          oDrawColor = neonTeal;
          lastAColor = baseColor;
        }

        // --- Dynamic Centering of the Entire Group ---
        // The base text spans from 0 to baseWidth.
        // The extra "io" group (when visible) extends to (shadowX_rel + oOffset + extraOWidth).
        // We compute a target offset so that the total spread is centered in the canvas.
        const extraFactor = Math.max(shadowOpacity, oOpacity); // 0 when invisible, 1 when fully visible.
        const extraGroupRight = shadowX_rel + oOffset + extraOWidth;
        const effectiveGroupWidth = baseWidth + Math.max(0, extraGroupRight - baseWidth) * extraFactor;
        const targetGroupOffsetX = (canvasWidth - effectiveGroupWidth) / 2;
        // Smoothly ease the current group offset toward the target.
        currentGroupOffsetX = lerp(currentGroupOffsetX, targetGroupOffsetX, 0.1);

        // --- Draw Base Text Letters ---
        ctx.font = baseFont;
        for (let i = 0; i < letters.length; i++) {
          const x = currentGroupOffsetX + basePositions[i];
          const color = i === letters.length - 1 ? lastAColor : baseColor;
          ctx.fillStyle = color;
          ctx.fillText(letters[i], x, canvasHeight / 2);
        }

        // --- Draw the Moving Shadow "i" ---
        if (shadowOpacity > 0) {
          ctx.save();
          ctx.globalAlpha = shadowOpacity;
          ctx.font = shadowFont;
          ctx.fillStyle = shadowDrawColor;
          const x = currentGroupOffsetX + shadowX_rel;
          ctx.fillText('i', x, canvasHeight / 2);
          ctx.restore();
        }

        // --- Draw the Extra "o" ---
        if (oOpacity > 0) {
          ctx.save();
          ctx.globalAlpha = oOpacity;
          ctx.font = shadowFont;
          ctx.fillStyle = oDrawColor;
          const x = currentGroupOffsetX + shadowX_rel + oOffset;
          ctx.fillText('o', x, canvasHeight / 2);
          ctx.restore();
        }

        animationFrameId = requestAnimationFrame(draw);
      }

      animationFrameId = requestAnimationFrame(draw);
      return () => cancelAnimationFrame(animationFrameId);
    });
  }, []);

  return <canvas ref={canvasRef} />;
};

export default BrandNameAnimation;

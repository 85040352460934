import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { APIEndpoint, APIClient } from '../../lib/api_client';
import { GoogleMap, useLoadScript, MarkerF, Libraries } from '@react-google-maps/api';
import { usePlan } from '../../hooks/PlanProvider';
import { useQuery } from '@tanstack/react-query';
import { Activity, CustomPlace } from '../../types/interfaces';
import Path from './Path';
import ActivityDrawer from '../ActivityDrawer/ActivityDrawer';
// import RealTimeNavigation from './RealTimeNavigation';
import { GoogleMapsClient } from '../../lib/maps_client';
import Cookies from 'js-cookie';
import { MapMarker, MemoizedMapMarker } from './MapMarker';
import './Map.scss';
import { Alert, CircularProgress } from '@mui/material';
import { ActivityTypes, FilterOptions, FilterPanel } from './FilterPanel';
import { DEFAULT_LOCATION, useGeolocation } from '../../hooks/GeolocationProvider';
import PlanStepperBase from '../PlanStepper/PlanStepperBase';
import PlanStepperHeader from '../PlanStepper/PlanStepperHeader';

const MAP_DEFAULT_ZOOM = 16;
const MAP_LIBRARIES: Libraries = ['places', 'geometry'];
export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  { elementType: 'geometry', stylers: [{ color: '#ffffff' }] },
  { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#333333' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] },
  { featureType: 'administrative', elementType: 'geometry.stroke', stylers: [{ color: '#cccccc' }] },
  { featureType: 'administrative.land_parcel', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi', stylers: [{ visibility: 'off' }] },
  { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] },
  { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#767676' }] },
  { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#dddddd' }] },
  { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#ffffff' }] },
  { featureType: 'transit', stylers: [{ visibility: 'off' }] },
  { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e6f7ff' }] },
  { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] }
];
export const MAP_OPTIONS: google.maps.MapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  streetViewControl: false,
  styles: MAP_STYLES,
  zoomControl: false,
};
export const MAP_DEFAULT_FILTER_OPTIONS: FilterOptions = {
  activityTypes: [ActivityTypes.All, ActivityTypes.Restaurant, ActivityTypes.Bar],
  priceRange: [0, 4],
  ambiance: [],
  distance: 5000
};


const Map: React.FC = () => {
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', libraries: MAP_LIBRARIES });
  const { userGeolocation } = useGeolocation();
  const { selectedPlan } = usePlan();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentActivity, setCurrentActivity] = useState<Activity | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<FilterOptions>(MAP_DEFAULT_FILTER_OPTIONS);
  const [isCollapsed, setIsCollapsed] = useState(selectedPlan.activities.length === 0);

  const mapsClient = useMemo(() => new GoogleMapsClient(), []);
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => { if (isLoaded) mapsClient.initialize() }, [isLoaded, mapsClient]);
  useEffect(() => {
    if (userGeolocation && mapRef.current) {
      mapRef.current.panTo(userGeolocation);
    }
  }, [userGeolocation]);
  useEffect(() => {
    if (selectedPlan.activities.length === 0) {
      setIsCollapsed(true)
    }
  }, [selectedPlan.activities])

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    // Only add event listeners if map and div exist
    if (map && map.getDiv()) {
      const mapDiv = map.getDiv();
      mapDiv.addEventListener('touchstart', () => { }, { passive: true });
      mapDiv.addEventListener('touchmove', () => { }, { passive: true });
    }
  }, []);

  const fetchMarkers = async (): Promise<CustomPlace[]> => {
    const response = await APIClient.get(`${APIEndpoint}/markers/`, {
      headers: { Accept: 'application/json' },
      params: userGeolocation || DEFAULT_LOCATION
    });
    return response.data || [];
  };

  const fetchFilteredMarkers = (): CustomPlace[] => {
    const filteredActivities = fetchMarkersQuery?.data?.filter((activity) => {
      const filterConditions = [
        () => activity?.types?.some((type) => filters.activityTypes.includes(type)),
        // If the priceLevel is undefined, we allow it by usage of inifinity
        () => (activity?.priceLevel || Infinity) >= filters.priceRange[0],
        () => (activity?.priceLevel || -Infinity) <= filters.priceRange[1],
        () => google.maps.geometry.spherical.computeDistanceBetween(userGeolocation || DEFAULT_LOCATION, activity) <= filters.distance
      ]
      return filterConditions.every((condition) => condition());
    })
    return filteredActivities || []
  };

  const fetchMarkersQuery = useQuery<CustomPlace[]>({
    queryKey: ['fetchFilteredMarkers'],
    queryFn: fetchMarkers,
    enabled: !!Cookies.get("mindara_at"),
    retry: false
  });

  const handleMarkerClick = (place: CustomPlace) => {
    setCurrentActivity(place as Activity);
    setDrawerOpen(true);
  };

  if (loadError) {
    return <Alert severity="error">Error loading maps</Alert>;
  }

  if (!isLoaded) {
    return (<div className='loading-overlay'><CircularProgress /></div>)
  } else {
    return (
      <div data-testid="google-map-container" style={{ width: '100%', height: '100%' }}>
        <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={userGeolocation || DEFAULT_LOCATION} zoom={MAP_DEFAULT_ZOOM}
          options={MAP_OPTIONS}
          onLoad={onMapLoad}
        >
          {userGeolocation &&
            <MarkerF position={userGeolocation} icon={{
              path: google.maps.SymbolPath.CIRCLE, scale: 8, fillColor: '#00ffff',
              fillOpacity: 1, strokeColor: '#ffffff', strokeWeight: 2
            }} zIndex={1000}
            />
          }

          {fetchMarkersQuery.data && fetchFilteredMarkers().map((place) => (
            <MemoizedMapMarker
              key={`memo-marker-${place.placeId}`}
              place={place}
              onClick={() => handleMarkerClick(place)}
            />
          ))}

          {selectedPlan.activities.map((activity, idx) => (
            <div key={activity.placeId}>
              <MapMarker
                isUser={false}
                customPlace={activity}
                onClick={() => handleMarkerClick(activity)}
              />
              <Path
                key={`path-${activity.placeId}`}
                originCoords={idx === 0 ? (userGeolocation || DEFAULT_LOCATION) : selectedPlan.activities[idx - 1]}
                destinationCoords={activity || selectedPlan.activities[0]}
                isActive={true}
                mapsClient={mapsClient}
              />
            </div>
          ))}
        </GoogleMap>
        <FilterPanel
          filters={filters}
          onChange={(newFilters) => setFilters(newFilters)}
          isOpen={isFilterOpen}
          onToggle={() => setIsFilterOpen(!isFilterOpen)}
        />

        <ActivityDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          selectedActivity={currentActivity as Activity}
        />


        {isCollapsed ? (
          <button className="plan-stepper-button" onClick={() => setIsCollapsed(!isCollapsed)} data-testid="plan-button">
            {selectedPlan.name} ({selectedPlan.activities.length})
          </button>
        ) : (
          <PlanStepperBase
            headerComponent={<PlanStepperHeader collapseHandler={() => setIsCollapsed(!isCollapsed)} />}
            onActivityClick={(activity) => {
              setCurrentActivity(activity as Activity)
              setDrawerOpen(true)
            }}
          />
        )}
      </div>
    )
  }
};

export default Map;

import React from 'react';
import PlanStepperBase from '../PlanStepper/PlanStepperBase';
import { PlanBuilderActivityRenderer } from './PlanBuilderActivityRenderer';
import './PlanBuilder.scss';
import PlanStepperHeader from '../PlanStepper/PlanStepperHeader';


const PlanBuilder: React.FC = () => {
  return (
    <PlanStepperBase
      headerComponent={<PlanStepperHeader />}
      ActivityRenderer={PlanBuilderActivityRenderer}
    />
  );
};

export default PlanBuilder;

import { AuthProvider } from './hooks/AuthProvider';
import { createTheme, ThemeOptions } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from '@emotion/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import BrowserRouterWithEffects from './lib/browser_router_with_effects';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { AppSnackbarProvider } from './hooks/SnackbarProvider';
import { GeolocationProvider } from './hooks/GeolocationProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PlanProvider } from './hooks/PlanProvider';
import { Shadows } from '@mui/material/styles/shadows';

declare module '@mui/material/styles' {
  interface Palette {
    custom: Palette['primary'];
  }
  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
  }
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 1 * (60 * 1000) } } // Milliseconds
})
const customShadows: Shadows = (() => {
  const shadows: string[] = [];
  for (let elevation = 0; elevation < 25; elevation++) {
    if (elevation === 0) {
      shadows.push('none');
    } else {
      const offsetX = elevation;
      const offsetY = elevation * 2;
      const blur = elevation * 8;
      const alpha = 0.20 + elevation * 0.04;
      const shadow = `${offsetX}px ${offsetY}px ${blur}px 0px rgba(0, 0, 0, ${alpha.toFixed(2)})`;
      shadows.push(shadow);
    }
  }
  return shadows as Shadows;
})();

const theme = createTheme({
  palette: {
    background: { default: '#ffffff', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
    primary: { main: '#f06ef5' },
    secondary: { main: '#ffc3a0' },
  },
  shape: { borderRadius: 16 },
  typography: { fontFamily: 'Inter, Roboto, sans-serif' },
  shadows: customShadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
          textTransform: 'none',
          borderRadius: 9999,
          background: 'linear-gradient(90deg, #ffafbd 0%, #ffc3a0 100%)',
          color: '#000000',
          '&:hover': {
            boxShadow: '0px 4px 8px rgba(0,0,0,0.4)',
          },
        },
      },
    },
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       background: `
    //         radial-gradient(
    //           circle at 20% 20%,
    //           rgba(255, 195, 215, 0.4) 0%,
    //           rgba(255, 220, 160, 0.4) 20%,
    //           rgba(255, 249, 194, 0.4) 40%,
    //           rgba(218, 252, 208, 0.4) 60%,
    //           rgba(210, 244, 255, 0.4) 80%,
    //           rgba(235, 224, 255, 0.4) 90%,
    //           #ffffff 100%
    //         )
    //       `,
    //       borderRadius: 16,
    //       boxShadow: '0 12px 32px rgba(0,0,0,0.15)',
    //       color: '#333333'
    //     }
    //   }
    // },
  },
});

const foundersTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#039be5' },
    secondary: { main: '#d85bd0' },
    warning: { main: '#ff9700' },
  },
  typography: { fontFamily: 'Roboto,sans-serif' },
});

// Initialize the Stripe object with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "")

root.render(
  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com" crossOrigin="anonymous" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" crossOrigin="anonymous" />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Audiowide:wght@300;400;500;700&display=swap" crossOrigin="anonymous" />
    <div className="index-base">
      <header className="index-header">
        <ErrorBoundary>
          <BrowserRouterWithEffects>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <ThemeProvider theme={theme}>
                    <GeolocationProvider>
                      <AppSnackbarProvider>
                        <PlanProvider>
                          <Elements stripe={stripePromise}>
                            <App />
                          </Elements>
                        </PlanProvider>
                      </AppSnackbarProvider>
                    </GeolocationProvider>
                  </ThemeProvider>
                </AuthProvider>
                {process.env.NODE_ENV !== 'production' && (<ReactQueryDevtools initialIsOpen={false} />)}
              </QueryClientProvider>
            </GoogleOAuthProvider>
          </BrowserRouterWithEffects>
        </ErrorBoundary>
      </header>
    </div>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
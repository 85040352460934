import { FC, useState } from 'react';
import { Box, Typography, Button, Grid, Paper, ButtonOwnProps } from '@mui/material';
import useResy from '../../hooks/useResy';
import resyLogo from '../../assets/resy-logo.png';
import uberLogo from '../../assets/uber-logo.png';
import lyftLogo from '../../assets/lyft-logo.png';
import ResyModal from './ResyModal';
import './Integrations.scss';
import { ConnectionState } from 'src/types/interfaces';
import { APIClient, APIEndpoint } from 'src/lib/api_client';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '../LoadingButton/LoadingButton';

export interface IntegrationsProps { }
export interface IntegrationModalProps {
  open: boolean;
  connectionState: ConnectionState;
  onClose: () => void;
}
export interface IntegrationProps {
  name: string;
  logo: string;
  connectionState: ConnectionState;
  ModalComponent: FC<IntegrationModalProps>;
  onReconnect: () => void;
}

/**
 * Renders the Integrations component, displaying a list of integration options.
 */
const Integrations: FC<IntegrationsProps> = () => {
  const { status } = useResy();
  const queryClient = useQueryClient();
  const handleReconnect = async () => {
    console.debug("Handling Resy reconnect")
    try {
      let response = await APIClient.post(`${APIEndpoint}/services/resy/reconnect/`);
      if (response.status === 200) {
        queryClient.invalidateQueries({ queryKey: ['resyConnection'] })
      }
    } catch (error) {
      console.error("Failed to preconnect to Resy", error);
    }
  }

  const integrations = [
    { name: 'Resy', logo: resyLogo, connectionState: status.data || ConnectionState.Unknown, ModalComponent: ResyModal, onReconnect: handleReconnect },
    { name: 'Uber', logo: uberLogo, connectionState: ConnectionState.Unknown, ModalComponent: ResyModal, onReconnect: () => { } },
    { name: 'Lyft', logo: lyftLogo, connectionState: ConnectionState.Unknown, ModalComponent: ResyModal, onReconnect: () => { } },
  ];

  // if (integrations.some((integration) => integration.connectionState === ConnectionState.Unknown)) {
  //   triggerSnackbar(`Pending integrations: don't worry - we're on it 😎`, 1, 'info');
  // }

  // const integrations = useMemo(() => [
  //   { name: 'Resy', logo: resyLogo, connectionState: resyState, ModalComponent: ResyModal, onReconnect: () => { console.log('would have reconnected') } },
  //   { name: 'Uber', logo: uberLogo, connectionState: ConnectionState.Unknown, ModalComponent: ResyModal, onReconnect: () => { } },
  // ], [resyState]);

  // useCallback(() => {
  //   if (integrations.some((integration) => integration.connectionState === ConnectionState.Unknown)) {
  //     triggerSnackbar(`Pending integrations: don't worry - we're on it 😎`, 1, 'info');
  //   }
  // }, [integrations, triggerSnackbar]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 8, pb: 7 }}>
        <Box sx={{ p: 3 }} data-testid="Integrations">
          <Typography variant="h4" gutterBottom>Integrations</Typography>
          <Grid container spacing={2}>
            {integrations.map((integration) => (
              <Grid item xs={12} key={integration.name}>
                <Integration {...integration} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

/**
 * A functional component that renders an integration card with a logo, name, and connection status.
 */
interface IntegrationSettings {
  text: string;
  color: ButtonOwnProps["color"];
  disabled?: boolean;
  loads?: boolean;
}

const Integration: FC<IntegrationProps> = ({ name, logo, connectionState, onReconnect, ModalComponent }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const buttonSettings: Record<ConnectionState, IntegrationSettings> = {
    [ConnectionState.Connected]: { text: 'Disconnect', color: 'secondary' },
    [ConnectionState.Disconnected]: { text: 'Connect', color: 'primary' },
    [ConnectionState.Disabled]: { text: 'Reconnect', color: 'primary', loads: true },
    [ConnectionState.Unknown]: { text: 'Pending', color: 'warning', disabled: true },
  }
  const { text, color, disabled, loads } = buttonSettings[connectionState];

  return (
    <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt={`${name} logo`} style={{ width: 40, height: 40, marginRight: 16 }} />
        <Typography variant="h6">{name}</Typography>
      </Box>
      {loads ? (
        <LoadingButton onClick={connectionState === ConnectionState.Disabled ? () => onReconnect() : () => setModalOpen(true)} color={color} disabled={disabled} variant="contained">
          {text}
        </LoadingButton>
      ) : (
        <Button onClick={connectionState === ConnectionState.Disabled ? () => onReconnect() : () => setModalOpen(true)} color={color} disabled={disabled} variant="contained">
          {text}
        </Button>
      )}
      <ModalComponent open={modalOpen} connectionState={connectionState} onClose={() => setModalOpen(false)} />
    </Paper>
  );
};

export default Integrations;

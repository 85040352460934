import { FC, useState } from 'react';
import { Modal, Button, Card, CardContent, CardHeader } from '@mui/material';
import useResy from '../../hooks/useResy';
import ResyModal from './ResyModal';
import './ResyBookingModal.scss';
import { Activity, ConnectionState, ResyReservation, ResyVenue, ResyVenueTimeSlot } from 'src/types/interfaces';
import { formatTimeSlotDate } from 'src/lib/utils';
import { usePlan } from 'src/hooks/PlanProvider';


interface ResyBookingModalProps {
  open: boolean;
  activity: Activity;
  venue: ResyVenue;
  onClose: () => void;
  onBookingComplete: (reservation: ResyReservation, venue: ResyVenue, slot: ResyVenueTimeSlot) => void;
}

const ResyBookingModal: FC<ResyBookingModalProps> = ({ open, activity, venue, onClose, onBookingComplete }) => {
  const [showResyConnect, setShowResyConnect] = useState(false);
  const { selectedPlan, setSelectedPlanActivities } = usePlan()
  const { status, book, cancel } = useResy();

  const handleTimeSlotClick = async (slot: ResyVenueTimeSlot) => {
    const reservation = await book(venue, slot);
    onBookingComplete(reservation, venue, slot);
    onClose();
  }

  const handleCancelClick = async (activity: Activity) => {
    await cancel(activity)
    const updatedActivities = selectedPlan.activities.map(existingActivity =>
      existingActivity.placeId === activity.placeId
        ? { ...existingActivity, resy: undefined }
        : existingActivity
    );
    setSelectedPlanActivities(updatedActivities);
    onClose();
  }

  return (
    <>
      <ResyModal
        open={showResyConnect}
        connectionState={status.data || ConnectionState.Unknown}
        onClose={() => setShowResyConnect(false)}
      />
      <Modal className="resy-booking-modal" open={open}>
        <Card>
          <CardHeader title="Choose a time slot:"></CardHeader>
          <Button onClick={() => onClose()}>Close</Button>
          <Button onClick={() => handleCancelClick(activity)}>Cancel</Button>
          <CardContent className="content">
            {venue.slots.map((slot: ResyVenueTimeSlot) => (
              <Button variant="outlined" onClick={() => handleTimeSlotClick(slot)}>
                {formatTimeSlotDate(slot.date.start).hour}:{formatTimeSlotDate(slot.date.start).minute}
              </Button>
            ))}
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default ResyBookingModal;
